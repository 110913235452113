<template lang="pug">
  .language-select
    .language-select-item(
      v-for="locale in localesExceptCurrent"
      @click="changeLocale(locale)"
      :key="locale"
    ) {{ upperCase(locale) }}
</template>

<script>
  // mixins
  import withLocale from "@/mixins/withLocale"

  // misc
  import { bus } from "@/config"
  import { difference, upperCase } from "lodash-es"

  export default {
    mixins: [withLocale],

    computed: {
      localesExceptCurrent() {
        return difference(this.availableLocales, [this.currentLocale])
      }
    },

    methods: {
      upperCase,
      changeLocale(locale) {
        this.setLocale(locale)
        bus.emit("locale-changed", locale)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .language-select
    display: flex

    &-item
      cursor: pointer
      background: $default-white
      color: $default-black
      padding: 3px 6px
      border-radius: 3px
      margin: 2px
      transition: all 0.1s linear

      &:hover
        background: lighten($default-purple-light, 4%)
</style>
